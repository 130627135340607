import React from "react"

import { ScaleIcon, LightningBoltIcon, AcademicCapIcon, ShieldCheckIcon } from '@heroicons/react/outline'


const features = [
{
    name: 'Compétences',
    icon: AcademicCapIcon,
},
{
    name: 'Indépendance',
    icon: ScaleIcon,
},
{
    name: "Réactivité",
    icon: LightningBoltIcon,
},
{
    name: 'Responsabilité',
    icon: ShieldCheckIcon,
},
]



export default function KdpHeader() {
  return (

            <div className="bg-white ">


                    <div className="flex flex-col flex-grow bg-blue-500 rounded-lg text-center py-4 px-4 sm:py-8 sm:px-6 lg:px-8">
                        <h2 className="text-2xl font-extrabold text-white sm:text-3xl">
                        <span className="block">Un problème technique</span>
                        </h2>
                        <p className="mt-4 text-lg leading-6 text-blue-200">
                        Trouvez facilement un expert de confiance. Réactivité, qualité et efficacité garanties.
                        </p>
                        <a
                        href="/contact/devis/"
                        className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 sm:w-auto"
                        >
                        Demandez un devis
                        </a>
                    </div>


                    
                    

                    <div className="py-4 bg-white">
                      <div className="max-w-7xl">
                        <div className="lg:text-center">
                          <h3 className="mt-2 text-1xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                          Pourquoi choisir Dubois Expertise ?
                          </h3>
                        </div>

                        <div className="mt-10">
                          <dl className="space-y-10 md:space-y-0 md:grid md:gap-x-8 md:gap-y-10">
                            {features.map((feature) => (
                              <div key={feature.name} className="relative">
                                <dt>
                                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                                  </div>
                                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900  py-2">{feature.name}</p>
                                </dt>
                              </div>
                            ))}
                          </dl>
                        </div>
                      </div>
                    </div>

                    
            </div>



        )
    }